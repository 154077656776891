// IIFE
(function ($, window, document, undefined) {
  'use strict';

  /* globals
     config,
     tools,
     testimonials,
     bookSite
  */

  // Set basics up
  tools.start();

  // Start testimonials cycling
  setInterval(function(){ testimonials.cycle(); }, config.testimonialCycleSpeed);

  // Do stuff for the the reading site
  bookSite.start();

  // GA Event tracking
  $('.mc-signup-form').submit( function(e) {
    ga('send', 'event', {
      eventCategory: 'Form Signup',
      eventAction: 'submit',
      eventLabel: 'Learn Design Release Notification',
      transport: 'beacon'
    });
  });

})(jQuery, window, document);
